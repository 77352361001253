
import React, { useState, useEffect } from "react";
import { Subscription } from "./Subscription.jsx";
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';


function Message({ message, note }) {
    const navigate = useNavigate();
    var isCanceled = message.includes("canceled");
    return (

        <>
            <section className="flex flex-col justify-center h-screen ">
                <h3 className="font-semibold text-center">{message}</h3>
                <p className="font-base text-center italic">*{note}</p>
                <div className="flex justify-center mt-12">
                    {!isCanceled ? <Button type="submit" className=" font-medium button w-button h-12 w-52 my-2" onClick={() => navigate('../workspace')}>
                        Get started <AiOutlineArrowRight className="ml-2" />
                    </Button>
                        :
                        <Button type="submit" className=" font-medium button w-button h-12 w-52 my-2" onClick={() => navigate('../subscription')}>
                            Try again <AiOutlineArrowRight className="ml-2" />
                        </Button>
                    }
                </div>

            </section>
        </>

    )
};

export const Checkout = () => {
    const [message, setMessage] = useState("");
    const [note, setNote] = useState("");

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setMessage("Congratulations!🎉🎉 \n You are ready to explore FrameFocus!");
            setNote("Please wait for few seconds if you aren't able to login immediately.")
        }

        if (query.get("canceled")) {
            setMessage(
                "Payment is canceled -- try again later."
            );
        }
    }, []);

    return message ? (
        <Message message={message} note={note}/>
    ) : (
        <Subscription />
    );
}

