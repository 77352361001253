import CookieConsent from "react-cookie-consent";

export const CookieConsentBox = () => {
    return (
        <CookieConsent style={{background: "#518071"}} buttonStyle={{fontWeight: "bold", backgroundColor: "#C8FCEA"}} buttonClasses="sm:translate-y-12 xl:translate-y-6" expires={150}>
            <div className=' font-semibold text-xl mb-2'>
                We use cookies
            </div>
            <p>Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies.&nbsp; 
                <a href='/privacy-policy' className='underline hover:text-yellow-1'>
                Find out how we use cookies
                </a>
            </p>
        </CookieConsent>
    )
}