import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Home } from "./pages/Home.jsx";
import { Workspace } from "./pages/Workspace";
import { Page404 } from "./pages/404Page";
import { Login } from "./pages/Login";
import { CommonFunciton } from "./utils/commonFunctions";
import { useEffect, useState } from "react";
import Constants from "./utils/constants";
import { Subscription } from "./pages/Subscription.jsx";
import { Checkout } from "./pages/Checkout.jsx";
import { Settings } from "./pages/Settings";
import { Profile } from "./pages/Profile.jsx";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermOfSerive } from "./pages/TermOfService";
import { CookieConsentBox } from "./components/CookieConsent";

// type Props = {
//   isLoggedIn: boolean;
// };

export const App = () => {
  const [isLoggedIn, setLoggedin] = useState(false);
  const [isSubscribed, setSubscription] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [loggedInUserData, setUserData] = useState();


  var authenticateURL = Constants.DEV_SERVER_URL + "/api/auth/loggedin";
  var getBillingStatusURL = Constants.DEV_SERVER_URL + "/api/get-billing-status";

  const authenticate = async () => {
    fetch(authenticateURL, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*'
      },
      credentials: 'include' // This is very important for sending cookie to server in order to use req.user
    })
      .then(async (response) => {
        const result = await response.json()

        setLoggedin(result === 200)
        getSubscriptionStatus();
      });
  }

  const getSubscriptionStatus = async () => {
    fetch(getBillingStatusURL, {
      mode: 'cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include' // This is very important for sending cookie to server in order to use req.user
    })
      .then(async (response) => {
        const result = await response.json()
        setSubscription(result.status === 200);
        setUserData(result);
        setLoading(false);
        // console.log(result);
        if (result === 400) {
          return <Navigate to="/login" />
        }
      });
  }

  useEffect(() => {
    authenticate();
  }, []);

  // window.addEventListener("beforeunload", (event) => {
  //   authenticate();
  //   getSubscriptionStatus();
  //   console.log("API call before page reload");
  // });

  // window.addEventListener("unload", (event) => {
  //   authenticate();
  //   getSubscriptionStatus();
  //   console.log("API call after page reload");
  // });
  // console.log("isLoggedIn: "+isLoggedIn)
  // console.log("isSubscribed: "+isSubscribed)
  // console.log("isLoading: "+isLoading)


  return (
    // <ChakraProvider theme={theme}>
    isLoading ? <div></div> :
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/term-of-service" element={<TermOfSerive />} />

        <Route path="/workspace" element={(
          isLoggedIn ? (
            isSubscribed ? <Workspace /> : <Navigate to="/subscription" />
          ) : (
            <Navigate to="/login" />
          ))} />
        <Route path="/login" element={(
          isLoggedIn ? (
            isSubscribed ? <Navigate to="/workspace" /> : <Navigate to="/subscription" />
          ) : (
            <Login />
          ))}>
        </Route>
        <Route path="/subscription" element={(
          isLoggedIn ? (
            isSubscribed ? <Navigate to="/workspace" /> : <Subscription />
          ) : (
            <Navigate to="/login" />
          ))} />
        <Route path="/checkout" element={
          <Checkout />
        } />
        {/* <Route path="/settings" element={(
          isLoggedIn ? (
            isSubscribed ? <Workspace /> : <Navigate to="/subscription" />
          ) : (
            <Settings />
          ))} /> */}
        <Route path="/profile" element={(
          isLoggedIn ? (
            isSubscribed ? <Profile customerData={loggedInUserData}/> : <Navigate to="/subscription" />
          ) : (
            <Navigate to="/login" />
          ))} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <CookieConsentBox />
    </BrowserRouter>
    // </ChakraProvider>
  )
}

