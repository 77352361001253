import { useState, useRef, useEffect } from 'react'
import '../editor.css'
import Constants from "../utils/constants";
import EffectTrack from './EffectTrack';
import { AiFillMinusCircle, AiOutlineClose, AiOutlineMinus, AiOutlineZoomIn } from 'react-icons/ai'
import { IoIosPause, IoIosPlay } from "react-icons/io";
import { createFFmpeg, fetchFile, FFmpeg } from '@ffmpeg/ffmpeg' // https://github.com/ffmpegwasm/ffmpeg.wasm/blob/master/docs/api.md
import {  toBlobURL } from '@ffmpeg/util';

import { Button } from '@chakra-ui/react'


export default function RenderEditor({ videoUrl, videoBlob, zoomRanges, setZoomRanges }) {
    //Ref handling the initial video element before editing
    const playVideoRef = useRef()
    const processedVidRef = useRef()

    //Ref handling the progress bar/pin element
    const pinRef = useRef()
    //Boolean state to handle whether video is playing or not
    const [isPlaying, setPlaying] = useState(false)
    //Boolean state to handle the magnification factor
    const [magnificationFactor, setMagnificationFactor] = useState(1)

    // const [videoTimeStampDisplay, setVideoTimeStampDisplay] = useState([])
    const [timestampsOnTracker, setTimestampsOnTracker] = useState()
    const [videoTrack, setVideoTrack] = useState()
    const [effectTrack, setEffectTrack] = useState()
    //Ref to handle the current instance of ffmpeg when loaded
    const ffmpegRef = useRef()
    //Boolean state handling whether ffmpeg has loaded or not
	const [isLoaded, setLoaded] = useState(false)
    const [processedVideo, setProcessedVideo] = useState()
	const [processDone, setPrecessDone] = useState(false)
    //Integer state to handle the progress bars numerical incremation
    const [progress, setProgress] = useState(0)
    const marginOfPinAndVideoTrack = "mx-0"

    // const [numOfIntervals, setNumOfIntervals] = useState(1)

    const iconButtonTheme = {
        size: 24,
        strokeWidth: 10,
        color: '#ffffff'
    }

    // class ZoomRange {
    //     constructor(zoomInTimeStamp, zoomOutTimestamp, zoomInPosition) {
    //         this.zoomInTimeStamp = zoomInTimeStamp;
    //         this.zoomOutTimestamp = zoomOutTimestamp;
    //         this.zoomInPosition = zoomInPosition;
    //     }
    // }

    const [isZoomed, setZoomStatus] = useState(false)
    const [clickedEffectTrackId, setClickedEffectTrackId] = useState();

    //Function handling loading in ffmpeg
	const load = async () => {
        const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
        const ffmpeg = ffmpegRef.current;
        // ffmpeg.on('log', ({ message }) => {
        //     // messageRef.current.innerHTML = message;
        //     console.log(message);
        // });
        // toBlobURL is used to bypass CORS issue, urls with the same
        // domain can be used directly.
        try {

            await ffmpeg.load({
                coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
                wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
            });
            setLoaded(true);
        }
        catch (error) {
            // console.log(window.crossOriginIsolated);
            console.log(error.toString());

            // There is a issue currently that every 2 times that access /workspace page will have a 
            // `ReferenceError: SharedArrayBuffer is not defined` error 
            // With this error, the render feature will not work.
            // This issue is currently observed only in Chrome.
            // The temperary fixduring testing client is to refresh the page when there is an error.
            // See the post: https://stackoverflow.com/a/68567821 for browsers that support SharedArrayBuffer

            // But this issue was resolved by add
            // res.setHeader('Cross-Origin-Embedder-Policy', 'require-corp');
            // res.setHeader('Cross-Origin-Opener-Policy', 'same-origin');
            // to server side and run `npm run buildin client and add the `buil` file to server side and start the server.
            // Basically it means when we host the client page in server the issue was resolved.
            const errorMessage = error.toString();
            if (errorMessage.includes("SharedArrayBuffer")) {
                // window.location.reload();
            }
        }
		// try {
		// 	await ffmpegRef.current.load()

		// 	setReady(true)
		// }
		// catch (error) {
		// 	console.log(error)
		// }
	}

	//Loading in ffmpeg when this component renders
	useEffect(() => {
		ffmpegRef.current = createFFmpeg({
			log: true,
			corePath: 'https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js'
		})
		load()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


    // Function to set the value magnification factor
    const setManificationFactor = () => {
        switch (magnificationFactor) {
            case 1:
                setMagnificationFactor(2)
                break;
            case 2:
                setMagnificationFactor(3)
                break;
            case 3:
                setMagnificationFactor(1)
                break;
            default:
                break;
        }
    }

    //Function handling play and pause logic
    const playPause = () => {
        if (isPlaying) {
            playVideoRef.current.pause()
        }
        else {
            // if ((playVideoRef.current.currentTime >= timings[0].end)) {
            // 	playVideoRef.current.pause()
            // 	setPlaying(false)
            // 	currentlyGrabbedRef.current = { 'index': 0, 'type': 'start' }
            // 	playVideoRef.current.currentTime = timings[0].start
            // 	progressBarRef.current.style.left = `${timings[0].start / playVideoRef.current.duration * 100}%`
            // 	progressBarRef.current.style.width = '0%'
            // }
            playVideoRef.current.play()
        }
        setPlaying(!isPlaying)
    }

    const controlBar = () => {
        return (
            <div className='flex flex-row justify-center'>
                <Button onClick={() => playPause()} className='hover:bg-blue-950 rounded-full p-2'>
                    {isPlaying ? <IoIosPause size={iconButtonTheme.size} strokeWidth={iconButtonTheme.strokeWidth} color={iconButtonTheme.color} /> : <IoIosPlay size={iconButtonTheme.size} strokeWidth={iconButtonTheme.strokeWidth} color={iconButtonTheme.color} />}
                </Button>
                {/* <Button onClick={() => setManificationFactor()} className='hover:bg-blue-950 rounded-full p-2'>
                    <AiOutlineZoomIn size={iconButtonTheme.size} strokeWidth={iconButtonTheme.strokeWidth} color={iconButtonTheme.color} />
                    <AiOutlineClose size={iconButtonTheme.size} strokeWidth={iconButtonTheme.strokeWidth} color={iconButtonTheme.color} /><div className='text-white'>{magnificationFactor}</div>
                </Button> */}
                <button title='Preview' onClick={() => previewRender()} className='bg-white m-2 rounded-md p-2 hover:bg-hover font-semibold text-zinc-600'>Preview</button>
            </div>
        )
    }

    useEffect(() => {
        playVideoRef.current.onloadedmetadata = () => {
            updateTracker();
            // if (zoomRanges[0].zoomOutTimeStamp === Constants.DUMMY_END_TIMESTAMPS) {
            // 	setZoomRanges([{ 'zoomInTimestamp': 0, 'zoomOutTimestamp': playVideoRef.current.duration }])
            // }
        }

        const handleResize = () => {
            updateTimestampsElement();

            // updateVideoTrack();
        };
        // Saw issue when jump into profile from workspace and resize the window cause error
        // because this handleResize is still calling in profile window
        if (window.location.href.includes('/workspace')) {
            window.addEventListener("resize", handleResize);

        }
        
        // // Cleanup: Remove event listener on component unmount
        // return () => {
        //     window.removeEventListener("resize", handleResize);
        // };

    }, [])

    const getNewPositionBasedOnRatio = (ratio, divWidth) => {


        // Baed on {marginOfPinAndVideoTrack}, mx-4 = 16px
        var margin = 0;
        var newPosition = divWidth * ratio + margin;
        return newPosition

    }
    const updatePinPosition = () => {
        var currentTime = playVideoRef.current.currentTime;
        var duration = playVideoRef.current.duration;
        var ratio = currentTime / duration;

        // var marginLeft = getComputedStyle(document.getElementById('videoTrack').marginLeft;



        // console.log("currentTime: "+currentTime)
        // console.log("duration: "+duration)
        // console.log("ratio: "+ratio)
        // console.log("marginLeft"+marginLeft)
        const divWidth = document.getElementById('videoTrack').offsetWidth;
        const newPosition = getNewPositionBasedOnRatio(ratio, divWidth);
        pinRef.current.style.margin = `${newPosition}px`;

        // console.log(pinRef.current.style.left)


        // console.log(newPosition)
        // div.setAttribute("style", `transform: translate(${newPosition}px, 0px);`);

    }
    const updateTimestampsElement = () => {
        const numIntervals = getNumOfIntervalsBasedOnDivWidth();
        const displayTimeStamps = getVideoDuration(numIntervals);
        const timeStampsElement = generateTimestampAboveTracker(displayTimeStamps);

        // setNumOfIntervals(numIntervals);
        // setVideoTimeStampDisplay(displayTimeStamps);
        setTimestampsOnTracker(timeStampsElement);
    }

    const updateVideoTrack = () => {
        const videoTrackElement = generateVideoTracker();
        setVideoTrack(videoTrackElement);
    }

    const updateEffectTrack = () => {
        // const effectTrackElement = <EffectTrack
        //     playVideoRef={playVideoRef} 
        //     marginOfPinAndVideoTrack={marginOfPinAndVideoTrack}
        //     zoomRanges={zoomRanges} 
        //     />;
        // setEffectTrack(effectTrackElement);
        // for (var i = 1; i < zoomRanges.length; i++) {
        //     var ele =document.getElementById(`effectTrack-${i}`);
        //     if (ele !== null) {
        //         // ele.addEventListener("click", addBorderAndDeleteButton(ele.id));
        //     }
        // }
        
       
    }

    const updateTracker = () => {
        updateTimestampsElement();
        updateVideoTrack();
    }
    const getNumOfIntervalsBasedOnDivWidth = () => {
        var div = document.getElementById('tracker');
        if (div === undefined || div === null) {
            return 1;
        }
        var divWidth = div.offsetWidth;
        // The div of time that displayed above the tracker - "00:00:00 | | | |"
        // the width of "00:00:00" is 64px (w-16 in tailwind)
        var timeDivWidth = 64;
        // the width of "|" is 16px (w-4 in tailwind)
        var timeIconDivWidth = 16
        var numIconDiv = 4;
        const totalTimeDiv = 4 * timeIconDivWidth + timeDivWidth;

        var numIntervals = Math.floor(divWidth / totalTimeDiv);
        if (numIntervals === 0) numIntervals = 1;

        return numIntervals;
    }
    const getVideoDuration = (intervalNum) => {
        if (playVideoRef != null && playVideoRef.current != null) {
            var duration = playVideoRef.current.duration;
            var intervalSec = duration / intervalNum
            var totalSec = 0
            var displayTimeStamps = []
            for (var i = 0; i <= intervalNum; i++) {
                displayTimeStamps.push(convertNumberTimestampToStringFormat(i * intervalSec + totalSec))
            }

            return displayTimeStamps
        }

    }
    const convertNumberTimestampToStringFormat = (timestampInSec, includeMilliSec = false) => {
        // For exmple: timestampsInSec = 10000

        // hours = 10000 / 60 / 60 = 166 = 2
        const hours = Math.floor(timestampInSec / 60 / 60);
        var minutes = 0;
        var seconds = 0;
        var remindTimeStamp = 0


        if (hours > 0) {
            // minutes = (10000 - 2 * 60 * 60) / 60 = 2800 / 60 = 46
            minutes = Math.floor((timestampInSec - hours * 60 * 60) / 60);
        } else {
            // minutes = 10000 / 60 = 166
            minutes = Math.floor(timestampInSec / 60);
        }


        // seconds = 1000 % 60 = 40
        seconds = Math.floor(timestampInSec % 60);
        const millisecs = Math.floor((timestampInSec % 60 - seconds) * 1000)
        const hoursStr = hours.toString().padStart(2, "0");
        const minutesStr = minutes.toString().padStart(2, "0");
        const secondsStr = seconds.toString().padStart(2, "0");
        const millisecsStr = millisecs.toString().padStart(2, "0");

        if (includeMilliSec) {
            return `${hoursStr}:${minutesStr}:${secondsStr}:${millisecsStr}`;
        } else {
            return `${hoursStr}:${minutesStr}:${secondsStr}`;
        }
    }

    const jumpToSPecificTimeStamps = (event) => {
        const clickX = event.clientX;
        const div = document.getElementById("timestampDiv");
        const divLeft = div.offsetLeft;
        const divWidth = div.offsetWidth;
        const ratio = (clickX - divLeft) / divWidth;
        const newPosition = getNewPositionBasedOnRatio(ratio);
        pinRef.current.style.margin = `${newPosition}px`;

        const duration = playVideoRef.current.duration;
        playVideoRef.current.currentTime = duration * ratio;
        // console.log(event.clientX)
        // console.log(divLeft)
        // console.log(divWidth)


    }

    const generateTimestampAboveTracker = (displayTimeStamps) => {
        var timestamps = [];
        var count = 0
        if (displayTimeStamps === undefined || displayTimeStamps === null) {
            return <></>
        }
        displayTimeStamps.forEach((timestamp) => {
            var key = `${count}`
            var key1 = `${count}-1`
            var key2 = `${count}-2`
            var key3 = `${count}-3`
            var key4 = `${count}-4`
            timestamps.push(
                <div className=' w-16' key={key}> {timestamp}</div>
            );
            if (count < displayTimeStamps.length - 1) {
                timestamps.push(
                    <div key={key1} className='m-auto w-4'>
                        <AiOutlineMinus className=' rotate-90' color='#4B5563' />
                    </div>

                )
                timestamps.push(
                    <div key={key2} className='m-auto w-4'>
                        <AiOutlineMinus className=' rotate-90' color='#4B5563' />
                    </div>
                )
                timestamps.push(
                    <div key={key3} className='m-auto w-4'>
                        <AiOutlineMinus className=' rotate-90' color='#4B5563' />
                    </div>
                )
                timestamps.push(
                    <div key={key4} className='m-auto w-4'>
                        <AiOutlineMinus className=' rotate-90' color='#4B5563' />
                    </div>
                )
            };
            count++;
        })


        return (

            <div id="timestampDiv" className='timestamp flex flex-row text-gray-400 text-sm font-light justify-evenly' onClick={(event) => jumpToSPecificTimeStamps(event)}>
                {timestamps}
            </div>
        )
    }

    const generateVideoTracker = () => {
        var style = `bg-blue-600 h-20 border mt-2 ${marginOfPinAndVideoTrack} border-blue-300 rounded-lg opacity-75`
        return (
            <div id="videoTrack" className={style}>

            </div>
        )
    }

    const generatePin = () => {
        var style = `bg-white ${marginOfPinAndVideoTrack} rounded-md w-0.5 h-28 absolute opacity-50`
        return <div ref={pinRef} className={style}></div>

    }
    const tracker = () => {
        return (
            <>
                <div id='tracker' key="test" className='w-2/3 flex flex-col m-auto tracker select-none justify-center text-white'>
                    {/* <div className='w-full h-full '> */}
                    {generatePin()}
                    {/* </div> */}
                    {/* <canvas className='w-full'> */}
                    {timestampsOnTracker}
                    {/* </canvas> */}
                    {videoTrack}
                    {/* {effectTrack} */}
                    <EffectTrack
                        playVideoRef={playVideoRef} 
                        marginOfPinAndVideoTrack={marginOfPinAndVideoTrack}
                        zoomRanges={zoomRanges} 
                        setZoomRanges={setZoomRanges}
                    />

                </div>
            </>
        )
    }

    const zoomInOut = (event) => {
        var height = playVideoRef.current.clientHeight
        var width = playVideoRef.current.clientWidth
        // console.log(`width: ${width}`)

        // console.log(`height: ${height}`)
        // console.log(`event.clientX: ${event.clientX}`)

        // console.log(`event.clientY: ${event.clientY}`)
        // console.log(event)

        /********************** ************************/

        let playVideoRect = playVideoRef.current.getBoundingClientRect()
        let xRatio = (event.clientX - playVideoRect.left) / playVideoRect.width
        let yRatio = (event.clientY - playVideoRect.top) / playVideoRect.height
        // console.log(`xRatio: ${xRatio}`)
        // console.log(`yRatio: ${yRatio}`)
        var ranges = zoomRanges
        const videoRender = document.getElementById("videoRender");

        if (ranges[ranges.length - 1].zoomInTimestamp === playVideoRef.current.currentTime
            || ranges[ranges.length - 1].zoomOutTimestamp === playVideoRef.current.currentTime) {
            console.log("You have zoomed in/out in this frame")
        }
        
        else if (ranges[ranges.length - 1].zoomOutTimestamp === Constants.PENDING_END_TIMESTAMPS) {
            if (ranges[ranges.length - 1].zoomInTimestamp > playVideoRef.current.currentTime) {
                console.log("Zoomout timestamp cannot be earlier than zoomin timestamp")
            }
            else {
                ranges[ranges.length - 1].zoomOutTimestamp = playVideoRef.current.currentTime
                setZoomStatus(false);
                if (!videoRender.classList.contains("hover:cursor-zoom-in")) {
                    videoRender.classList.remove(["hover:cursor-zoom-out"])
                    videoRender.classList.add(["hover:cursor-zoom-in"])
                }
            }
            
        } else {
            ranges.push({ 'zoomInTimestamp': playVideoRef.current.currentTime, 'zoomOutTimestamp': Constants.PENDING_END_TIMESTAMPS, 'zoom_X_ratio': xRatio, 'zoom_Y_ratio': yRatio })
            setZoomStatus(true);
            videoRender.classList.remove(["hover:cursor-zoom-in"])
            videoRender.classList.add(["hover:cursor-zoom-out"])
        }
       
        setZoomRanges(ranges)
    }

    const previewRender = async (fileInput) => {
		const fileName = videoBlob.name
		// let metadata = {
		// 	'trim_times': timings,
		// 	'mute': isMuted
		// }
		// console.log(metadata.trim_times)
		// const trimStart = metadata.trim_times[0].start
		// const trimEnd = metadata.trim_times[0].end

		// const trimmedVideo = trimEnd - trimStart

		// console.log('Trimmed Duration: ', trimmedVideo)
		// console.log('Trim End: ', trimEnd)
        if(!isLoaded) {
            // ffmpegRef.current = createFFmpeg({
            //     log: true,
            //     corePath: 'https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js'
            // })
            // load()
        }
		try {
            setPrecessDone(false);

			const ffmpeg = ffmpegRef.current;
			ffmpeg.FS('writeFile', fileName, await fetchFile(videoUrl))

			ffmpeg.setProgress(({ ratio }) => {
				// console.log('ffmpeg progress: ', ratio)
				if (ratio < 0) {
					setProgress(0)
				}
                var progressPercentage = Math.round(ratio * 100);
                if (progressPercentage > 100) {
                    progressPercentage = 100;
                }
				setProgress(progressPercentage);
			})

			// console.log(videoBlob)
			let zoomCommand = buildFFmpegCommand()
			var ind1 = fileName.lastIndexOf('/')
			var ind2 = fileName.lastIndexOf('.')
			var originialFileName = fileName.substring(ind1 + 1, ind2)
			var outputFileName = originialFileName + "_processed" + fileName.substring(ind2)
			// console.log(`-i ${fileName} ${zoomCommand[0]} "zoompan=z=pzoom+0.01:x='iw/2-iw/zoom/2':y='ih/2-ih/zoom/2':d=1:s=1280x720:fps=30" -codec copy ${outputFileName}`)
			// await ffmpeg.run('-i', fileName, '-vcodec', 'libx264 -crf', '24', outputFileName)
			// await ffmpeg.run('-i', fileName, zoomCommand[0], zoomCommand[1], '-codec', 'copy', outputFileName)
			// await ffmpeg.run('-i', fileName, zoomCommand[0], "zoompan=z='if(between(in_time,0,1),2,1)':d=1:x='iw/2-(iw/zoom/2)':y='ih/2-(ih/zoom/2)'", outputFileName)
			// await ffmpeg.run('-i', fileName, '-codec', 'copy', outputFileName)
			// await ffmpeg.run('-i', fileName, '-vf', 'scale=320:-2', '-vcodec', 'libx264', '-crf', '20', outputFileName)

			// await ffmpeg.run('-i', fileName, '-vf', "zoompan=z=pzoom+0.003:d=1", outputFileName)
            // console.log(`FFmpeg command: ${zoomCommand}`)
            if (zoomCommand.length === 0) {
                await ffmpeg.run('-i', fileName, '-c:v', 'libx264', '-crf', '18', '-preset', 'slow', '-c:a', 'copy', outputFileName)

            } else {
                await ffmpeg.run('-i', fileName, '-vf', `${zoomCommand}`, '-c:v', 'libx264', '-crf', '18', '-preset', 'slow', '-c:a', 'copy', outputFileName)
            }

            // await ffmpeg.run('-i', fileName, '-t', '2.0', '-ss', '2.0', '-f', 'mp4', outputFileName)

			const data = await ffmpeg.FS('readFile', outputFileName)
			// console.log(`I am here: ${data.buffer}`)
			const url = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }))
			// const url = URL.createObjectURL("blob:http://localhost:3000/37acd6da-bf2c-4057-be80-b40374e9fe7b")
			setProcessedVideo(url);
            setPrecessDone(true);
			// setLottiePlaying(false)
		}
		catch (error) {
			console.log(error)
		}

	}

	const buildFFmpegCommand = () => {
		// var command = `-vf "zoompan=z='min(zoom+0.0015,1.5)':d=700:x='if(gte(zoom,1.5),x,x+1/a)':y='if(gte(zoom,1.5),y,y+1)':s=640x360"`;
		// const zoomInStartTimeStamps = 5.0;
        const timeTakeToZoom = 1.0;
        // const zoomInEndTimeStamps = zoomInStartTimeStamps + timeTakeToZoom;
        // const zoomOutStartTimeStamps = 10.0;
        // const zoomOutEndTimeStamps = zoomOutStartTimeStamps + timeTakeToZoom;
		const zoomSpeed = 200.0;
		const maxZoomScale = 4.0
        const zoomInitialScale = 1.0
        const fps = 30;
		// var commands = ["-vf", `zoompan=z='if(between(in_time,${zoomInStartTimeStamps},${zoomInEndTimeStamps}),min(max(zoom,pzoom)+${zoomSpeed},${maxZoomScale}),min(max(zoom,pzoom)-${zoomSpeed},${maxZoomScale}))':d=1:x='iw/2-(iw/zoom/2)':y='ih/2-(ih/zoom/2)'`]
		// var command = `zoompan=z='if(between(in,${zoomInStartTimeStamps}*${fps},${zoomInEndTimeStamps}*${fps}),min(zoom+0.001*${zoomSpeed},${maxZoomScale}), zoom)':d=1:x='iw/2-(iw/${maxZoomScale}/2)':y='ih/2-(ih/${maxZoomScale}/2)':s=1280x720:fps=30,scale=1280:720`;
		// var command = `zoompan=z='if(between(in,${zoomInStartTimeStamps}*${fps},${zoomInEndTimeStamps}*${fps}),min(zoom+0.001*${zoomSpeed},${maxZoomScale}),if(between(in,${zoomOutStartTimeStamps}*${fps},${zoomOutEndTimeStamps}*${fps}),max(zoom-0.001*${zoomSpeed},${zoomInitialScale}),${zoomInitialScale})':d=1:x='iw/2-(iw/${zoomInitialScale}/2)':y='ih/2-(ih/${zoomInitialScale}/2)'`;
        // var commands = `zoompan=z='`;
        var commands = ``;

        const ranges = zoomRanges.splice(1);
        const length = ranges.length;
        var count = 1;
        ranges.forEach((range) => {
            if(count > 1) {
                commands+=',';
            }
            const zoomInStartTimeStamps = range.zoomInTimestamp;

            const zoomInEndTimeStamps = zoomInStartTimeStamps + timeTakeToZoom;
            var zoomOutStartTimeStamps = range.zoomOutTimestamp;
            const zoomXRatio = range.zoom_X_ratio;
            const zoomYRatio = range.zoom_Y_ratio;

            // When zoomOutStartTimeStamps is Constants.DUMMY_END_TIMESTAMPS, it means
            // customer only zoomed in without zooming out, so we don't want to zoom out either
            if (zoomOutStartTimeStamps === Constants.DUMMY_END_TIMESTAMPS) {
                zoomOutStartTimeStamps = playVideoRef.current.duration + 1;
            }
            const zoomOutEndTimeStamps = zoomOutStartTimeStamps + timeTakeToZoom;
            var command = `zoompan=z='
            if(between(time,${zoomInStartTimeStamps},${zoomInEndTimeStamps}),min(max(zoom,pzoom)+0.001*${zoomSpeed},${maxZoomScale}),
                if(between(time,${zoomInEndTimeStamps},${zoomOutStartTimeStamps}),${maxZoomScale},
                    if(between(time,${zoomOutStartTimeStamps},${zoomOutEndTimeStamps}),max(max(zoom,pzoom)-0.001*${zoomSpeed},${zoomInitialScale}),
            ${zoomInitialScale})))':d=1:x='iw*${zoomXRatio}':y='ih*${zoomYRatio}'`;
            commands+=command;
            
            // const zoomCommand = 
            // `if(between(time,${zoomInStartTimeStamps},${zoomInEndTimeStamps}),min(zoom+0.001*${zoomSpeed},${maxZoomScale}),
            //     if(between(time,${zoomInEndTimeStamps},${zoomOutStartTimeStamps}),${maxZoomScale},
            //         if(between(time,${zoomOutStartTimeStamps},${zoomOutEndTimeStamps}),max(zoom-0.001*${zoomSpeed},${zoomInitialScale}),`;
            // commands += zoomCommand;
            count++;
        });
        // commands += `${zoomInitialScale}`;
        // ranges.forEach((range) => {
        //     commands += `)))`;
        // });
        // commands += `'`
        // var command = `zoompan=z='
        // if(between(time,${zoomInStartTimeStamps},${zoomInEndTimeStamps}),min(zoom+0.001*${zoomSpeed},${maxZoomScale}),
        //     if(between(time,${zoomInEndTimeStamps},${zoomOutStartTimeStamps}),${maxZoomScale},
        //         if(between(time,${zoomOutStartTimeStamps},${zoomOutEndTimeStamps}),max(zoom-0.001*${zoomSpeed},${zoomInitialScale}),
        // ${zoomInitialScale})))':d=1:x='iw/2-(iw/zoom/2)':y='ih/2-(ih/zoom/2)'`;

        // var command = `zoompan=z='
        // if(between(time,${zoomInStartTimeStamps},${zoomInEndTimeStamps}),min(zoom+0.001*${zoomSpeed},${maxZoomScale}),
        //     if(between(time,${zoomInEndTimeStamps},${zoomOutStartTimeStamps}),${maxZoomScale},
        //         if(between(time,${zoomOutStartTimeStamps},${zoomOutEndTimeStamps}),max(zoom-0.001*${zoomSpeed},${zoomInitialScale}),
        // ${zoomInitialScale})))':d=1:x='iw/2-(iw/zoom/2)':y='ih/2-(ih/zoom/2)',zoompan=z='
        // if(between(time,${zoomInStartTimeStamps+10},${zoomInEndTimeStamps+10}),min(zoom+0.001*${zoomSpeed},${maxZoomScale}),
        //     if(between(time,${zoomInEndTimeStamps+10},${zoomOutStartTimeStamps+10}),${maxZoomScale},
        //         if(between(time,${zoomOutStartTimeStamps+10},${zoomOutEndTimeStamps+10}),max(zoom-0.001*${zoomSpeed},${zoomInitialScale}),
        // ${zoomInitialScale})))':d=1:x='iw/2-(iw/zoom/2)':y='ih/2-(ih/zoom/2)'`;


        return commands
	}

    const renderedVideo = () => {
        return (
            <div className='bg-dark flex flex-row justify-center'>
					<video
						className='max-h-96'
						ref={processedVidRef}
						controls
						autoload='metadata'
						// onClick={() => console.log(processedVidRef.current.duration)}
					>
						<source src={processedVideo} type='video/mp4' />
					</video>
				</div>
        )
    }
    return (
        // <div className='wrapper'>
        <div className='mt-10'>

            {/* Main video element for the video editor */}
            {/* <div className='inner_wrapper row-span-3'> */}
            <div className='w-full flex flex-row justify-center'>

                <video className='max-h-96 hover:cursor-zoom-in '
                    autoload='metadata'
                    // muted={isMuted}
                    ref={playVideoRef}
                    id='videoRender'
                    // onLoadedData={() => {
                    //     playPause()
                    // }}
                    onClick={(event) => {
                        zoomInOut(event)
                    }}
                    onTimeUpdate={() => {
                        updatePinPosition();
                    }}

                    autoPlay={false}
                >
                    <source src={videoUrl} type='video/mp4' />
                </video>
            </div>
            {controlBar()}
            {tracker()}
            <div id="renderProcessPercentage" className='w-30 mt-20 p-5 italic text-center text-white font-light text-sm'>Render process (please refresh if it doesn't respond after 5 seconds after click Preview ): {progress}%</div>

            {processDone ?
				renderedVideo()
				: null
			}
        </div>
    )
}