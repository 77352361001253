import '../index.css';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
// import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import Constants from "../utils/constants";
import { useState } from 'react';
import { AiOutlineMail } from "react-icons/ai";



export const Login = () => {
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);

    // const navigate = useNavigate()

    async function handleSubmit(e: any) {
        e.preventDefault();

        var loginURL = Constants.DEV_SERVER_URL + "/api/auth/login/email" + "?email=" + email;

        var isValidEmail = validateEmail(email);

        
        if (isValidEmail) {
            const response = await fetch(loginURL, {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                },
                credentials: 'include'
            });
            if (response.status === 200) {
                setEmailSent(true);
                // const emailCheckNavigation = '../workspace'
                // navigate(emailCheckNavigation)
            }
        }
        
    };

    
    const validateEmail = (value: string) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
            return (true)
        }
        document.getElementById('error_text')!.style.visibility = "visible"
        document.getElementById('error_text')!.style.color = "#db544b"
        return (false)
    }

    const emailSentNotification = () => (
        <>
            <div className='flex flex-col justify-center my-6 mx-12'>
                <div className='flex justify-center'><AiOutlineMail size={40} color='#175cff'/></div>
                <h4 className=' font-semibold'>Check your email</h4>
                <p >We sent a magic link to your email address.<br />
                Click the link in that email to sign in.</p>
                <hr/>
                <p >Didn't receive the email? <a href="/login" className=' hover:text-'>Get another link</a></p>
            </div>
        </>
    )

    const logInWidget = () => (
        <>
            <LogoSVG className='h-10 m-4'></LogoSVG>
            <form className='flex flex-col justify-center' onSubmit={handleSubmit}>
                    <div className='flex flex-col justify-start mx-12'>
                        <label className='w-1 font-semibold text-lg mb-2'>
                            Email:
                        </label>
                        <input id="email" name="email" type="text" className=' h-14 rounded-md' autoComplete="username" required autoFocus onChange={(e: any) => setEmail(e.target.value)}/>
                    </div>
                    
                    <span id="error_text" className='font-bold invisible'>Invalid email address!</span>
                    <Button type="submit" className="font-medium button w-button mx-20 h-12">
                        Sign in with email
                    </Button>
                    {/* <GeneralButton type="submit" className="" content='Sign in with email' navigation='../workspace' /> */}

                </form>
        </>
    )
    return (
        <div className="flex justify-center text-center rounded-lg ">
            <div className="h-64 min-w-44 max-w-46 absolute top-1/3 m-auto bg-gray-200 overflow-hidden rounded-lg">
                
                {  (emailSent) ? emailSentNotification() : logInWidget()}
                
            </div>
        </div>

    )
}