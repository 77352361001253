import '../index.css';
import VideoEditor from '../components/VideoEditor.js';
import NavBarWithUserInformation from '../components/NavBarWithUserInformation';
import Footer from '../components/Footer';
import { Divider, StackDivider } from '@chakra-ui/react';
import { AiOutlineArrowLeft, AiOutlineBackward } from 'react-icons/ai';
import Constants from '../utils/constants';

const convertEpochTimestampToDate = (epochTime) => {
    const dateObject = new Date(epochTime * 1000)
    const humanDateFormat = dateObject.toString()
    // console.log(humanDateFormat);
    return humanDateFormat;
}
const cancelSubscription = async () => {
    var cancelSubscriptionURL = Constants.DEV_SERVER_URL + '/api/cancel-subscription';
    fetch(cancelSubscriptionURL, {
        mode: 'cors',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include' // This is very important for sending cookie to server in order to use req.user
    })
    .then(async (response) => {
        const result = await response.json();
        // console.log(result);
        if (result.status === 200) {
            window.location.reload();
        }
    });

}
const MainSection = ({ customerData }) => {
    return (
        <div className=' md:px-80 mt-20 mb-20 sm:px-20 w-full h-full'>
            <a href='../workspace' className='no-underline hover:underline'>
                <div className='flex flex-row text-center'>
                    <div className='my-auto'>
                        <AiOutlineArrowLeft color='#175cff' />
                    </div>
                    <span className=' text-lg text-darkblue'>back</span>

                </div>
            </a>
            <h4 className='font-bold mt-8'>Profile</h4>
            <hr className=' border-2' />
            <Divider color={'#f0f2f7'} />
            <div className='py-6'>
                <div className='flex flex-row row'>
                    <div className='w-44 mr-52'>
                        <p className='font-medium'>Email: </p>
                    </div>
                    <div className='w-44 mr-52'>
                        <p className='font-light'>{customerData.customer_email}</p>
                    </div>
                </div>

                <div>
                    <p className='font-medium '>Subscription Plan: </p>
                    {customerData.cancel_at_period_end ? <p className='text-sm'>
                        You can continue using the service until <span className='font-semibold'>{convertEpochTimestampToDate(customerData.current_period_end)}</span>
                    </p>
                        : <div className='text-sm'>
                            <p>Your last payment is on <span className='font-semibold'>{convertEpochTimestampToDate(customerData.current_period_start)}</span> </p>
                            <p>Your next payment is after <span className='font-semibold'>{convertEpochTimestampToDate(customerData.current_period_end)}</span> </p>
                            <button className='bg-hover hover:bg-gray-400 p-2 rounded-md ' onClick={() => cancelSubscription()}>Cancel the subscription</button>
                            <p className='text-xs italic mt-1'>* you will be able to continue using the service after cancel the subscription until the end of the current subscription</p>
                        </div>
                    }
                    <p></p>
                </div>
            </div>
        </div>
    )
}

export const Profile = ({ customerData }) => {
    return (
        <div className='bg-white h-full w-full '>
            <MainSection customerData={customerData} />
            <Footer />

        </div>
    )
}