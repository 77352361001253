import { AiOutlineCheck, AiOutlineArrowRight } from "react-icons/ai";
import { Button } from "@chakra-ui/react";
import Constants from "../utils/constants";
import { useNavigate } from "react-router-dom";

const pricings = {
    option1: {
        priceInDollars: "3",
        type: "1 Week Trial",
        description: "Start with a taste of FrameFocus",
        checklist: [
            "1 week trial without auto subscription after trial expired!",
            "Enjoy all the basic features!",
            "Cheap! (Cost less than a cup of coffee)"
        ]
    },
    option2: {
        priceInDollars: "8",
        type: "Monthly",
        description: "Make FrameFocus your daily essential",
        checklist: [
            "Cancel the subscription any time you want!",
            "Enjoy all the basic features!",
            "Unblock all the incoming premium features"
        ]
    }

};

function getChecklistWithIconWidget(checklist) {
    var checklistWithIcon = [];
    var count = 1;
    {
        checklist.forEach(description => {
            checklistWithIcon.push(
                <div key={`check-${count}`} className="flex flex-row my-4">
                    <AiOutlineCheck />
                    <span className="ml-2">{description}</span>
                </div>
            )
            count++;
        });
    }
    return checklistWithIcon
}


function Card({ option }) {
    var checklist = option.checklist;
    var checklistWithIcon = getChecklistWithIconWidget(checklist);
    var optionType = option.type;
    var description = option.description;
    var priceInDollars = option.priceInDollars;
    var cardStyle = "rounded-2xl w-1/2 min-w-60 max-w-lg p-6 m-6 bg-gray-100";
    var isMonthlyPlan = optionType === "Monthly";

    var stripServerURL = Constants.DEV_SERVER_URL + "/api/create-checkout-session";
    if (isMonthlyPlan) {
        stripServerURL += "?payPlan=monthly";
    } else {
        stripServerURL += "?payPlan=week-trial";
    }

    if (isMonthlyPlan) {
        cardStyle += " border-2 border-blue-500"
    } 

    const navigate = useNavigate();

    var button = <></>;
    if (window.location.href.includes('/subscription')) {
        button = <Button type="submit" className=" font-medium button w-button h-12 w-full my-2">
        Get started <AiOutlineArrowRight className="ml-2" />
    </Button>;
    }
    else {
        button = <Button onClick={() => {navigate('./subscription')}} className=" font-medium button w-button h-12 w-full my-2">
        Get started <AiOutlineArrowRight className="ml-2" />
    </Button>
    }
    return (
        <div id="price-plan-1" className={cardStyle}>
            {isMonthlyPlan ?
                <div className="  bg-blue-500 text-sm rounded-3xl font-medium text-white w-20 text-center -translate-y-3">POPULAR</div>
                :<div className="mt-0.5  bg-blue-500 text-sm rounded-3xl font-medium text-white w-20 text-center -translate-y-4 invisible">POPULAR</div>

            }
            <div>
                <h4 className=" font-bold">{optionType}</h4>
                <p className=" font-semibold">{description}</p>
            </div>
            <div className="mb-2 mt-6">
                <span className="font-bold text-4xl ">${priceInDollars}</span>
                <span>USD</span>
            </div>
            <div className="my-8">
                {checklistWithIcon}
            </div>
            <form action={stripServerURL} method="POST">
            {button}
            </form>
            
        </div>
    )
}
export default function PricingCards() {
    return (
        <>
            <div className="flex flex-row justify-center">
                <Card option={pricings.option1}/>
                <Card option={pricings.option2}/>
            </div>

        </>
    )
}

