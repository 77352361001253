import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Constants from "../utils/constants";


export default function GeneralButton({type = '', className = '', content = '', navigation = ''}) {
    const classStyle = className + " font-medium button w-button";
    const navigate = useNavigate();
    
    return (
        <Button className={classStyle} onClick={() => navigate(navigation)}>
            {content}
        </Button>
    )
}