import '../index.css'; 
import NavBar from "../components/NavBar"
import { ReactComponent as LogoSVG } from '../assets/logo.svg';

function FourHundredError() {
    return (
        <div className="px-16 py-32 bg-white relative overflow-hidden justify-center text-center">
            <div className="flex flex-col justify-center bg-white text-center">
                <h1>404 Error</h1>
                <p className='text-2xl'>Page Not Found</p>
            </div>
        </div>
    )
}
export const Page404 = () => (
    <>
        <NavBar/>
        <FourHundredError />
    </>
)