export default class Constants {
    // This is the dummy timestamps that used as an initial value
    static DUMMY_END_TIMESTAMPS = -1;

    // This is the timestamps that used as an value represent the pending timestamps waiting for assigning value
    static PENDING_END_TIMESTAMPS = -2;

    static DEV_SERVER_URL = "https://api.framefocus.io";
    // static DEV_SERVER_URL = "http://localhost:8080";

}