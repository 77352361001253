
import NavBarWithOulyLogo from "../components/NavBarWithOnlyLogo";
import PricingCards from "../components/PricingCards.jsx";

export const Subscription = () => (
    <div className="bg-white h-full ">
        <NavBarWithOulyLogo/>

        <div className="flex flex-col justify-center ">
            <h1 className=" text-center mt-20 mb-5">You have logged in!</h1>
            <h3 className=" text-center mb-10">Let's choose your plan</h3>
            <PricingCards />
            {/* <div className="my-20"></div> */}

        </div>
    </div>
)

