import * as React from "react"
import '../index.css'; 

import { Navigate, Link as ReactRouterLink, useNavigate } from "react-router-dom";
import {
    Box,
    Stack,
    Flex,
    Menu,
    MenuButton,
    IconButton,
    MenuList,
    MenuDivider,
    MenuItem,
    color
} from "@chakra-ui/react"
import { HamburgerIcon, AddIcon, ExternalLinkIcon, RepeatIcon, EditIcon, SettingsIcon } from '@chakra-ui/icons'

import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import GeneralButton from "./GeneralButton";
import { AiFillProfile, AiOutlineArrowLeft, AiOutlineBackward, AiOutlineLogout, AiOutlineSetting, AiOutlineUser } from "react-icons/ai";
import Constants from "../utils/constants";

function Back() {
    return (
        <Box as={ReactRouterLink} to='/'>
            <AiOutlineArrowLeft color="#ffffff" size={25} strokeWidth={10}/>
        </Box>
    )
}



const NavMenu = () => {
    const navigate = useNavigate();

    function navigateTo(location: any) {
        navigate(location);
    }
    
    async function signOut() {
        var logOutURL = Constants.DEV_SERVER_URL + "/api/auth/logout";

            fetch(logOutURL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include' // This is very important for sending cookie to server in order to use req.user
            })
              .then(async (response) => {
                const result = await response.json()
                // console.log(result);
                // console.log(result)
                if (result === 200) {
                  window.location.href = '../'
                }
              });
          
     // TODO
    }
    
    return (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label='Options'
                icon={<SettingsIcon boxSize={25} id="setting" margin={10} color={'#ffffff'}/>}
                variant='outline'
                // borderRadius='md'
                // borderWidth='1px'
                className=" hover:bg-gray-200 p-4 rounded-full "
            />
            <MenuList className="border rounded-md border-darkblue w-60 p-1 bg-white" >
                {/* <MenuItem icon={<AiOutlineSetting size={20} color="#175cff" strokeWidth={10}/>} className="rounded-md font-medium menuItem p-1 mb-1 hover:bg-hover" onClick={() => navigateTo('../Settings')}>
                    <span className="text-darkblue ">Settings</span>
                </MenuItem> */}
                <MenuItem icon={<AiOutlineUser size={20} color="#175cff" strokeWidth={10}/>} className="rounded-md font-medium menuItem p-1 mb-1 hover:bg-hover" onClick={() => navigateTo('../Profile')}>
                    <span className="text-darkblue ">Profile</span>
                </MenuItem>
                <MenuDivider color={'#d8d9de'} className="my-1" />
                <MenuItem icon={<AiOutlineLogout  size={20} color="#175cff" strokeWidth={10}/>} className="rounded-md font-medium menuItem p-1 hover:bg-hover" onClick={() => signOut()}>
                    <span className="text-darkblue ">Sign Out</span>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

export default function NavBarWithUserInformation() {
    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            pb={2}
            pt="24px"
            pl="64px"
            pr="64px"
            className='bg-dark'
        >
            <Back />
            <NavMenu />
        </Flex>

    )
}