import * as React from "react"
import { Link as ReactRouterLink } from "react-router-dom";
import {
    Box,
    Stack,
    Flex
} from "@chakra-ui/react"

import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import GeneralButton from "./GeneralButton";

function Logo() {
    return (
        <Box as={ReactRouterLink} to='/'>
            <LogoSVG className="h-14 w-60"/>
        </Box>
    )
}

export default function NavBarWithOulyLogo() {
    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            pb={2}
            pt="24px"
            pl="64px"
            pr="64px"
            bg={["white"]}
            color={["white", "white", "primary.700", "primary.700"]}
        >
            <Logo />
        </Flex>
    
    )
}