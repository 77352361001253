import { useState } from 'react'
import '../editor.css'
import { AiFillMinusCircle } from 'react-icons/ai'


export default function EffectTrack({playVideoRef, marginOfPinAndVideoTrack, zoomRanges, setZoomRanges}) {
    const [clickedEffectTrackId, setClickedEffectTrackId] = useState("unset");


    const insertClassAtPosition = (element, classNames, index) => {
        let l = element.className.split(" ");
        for (var i = 0; i < classNames.length; i++) {
            l.splice(index + i, 0, classNames[i]);
            element.className = l.join(" ");
        }
        // l.splice(index, 0, className);
        // element.className = l.join(" ");
    }

    const getNewPositionBasedOnRatio = (ratio, divWidth) => {


        // Baed on {marginOfPinAndVideoTrack}, mx-4 = 16px
        var margin = 0;
        var newPosition = divWidth * ratio + margin;
        return newPosition

    }

    const addBorderAndDeleteButton = (id) => {
        // console.log("e: " + e.target.id)
        // const id = e.target.id
        // const id = `effectTrack-${key}`;

        var element = document.getElementById(id);
        var index = id[id.length - 1];
        // console.log("index: "+index)

        const buttonElement = document.getElementById(`deleteButton-${index}`);

        if (element !== null) {
            const additionalStyle = ["border-2"];
            const removedStyles = ["hidden"];
            if (clickedEffectTrackId !== id) {
                var previousButtonElement;
                var previousIndex;
                
                if (clickedEffectTrackId !== "unset" && clickedEffectTrackId !== null) {
                    // console.log("clickedEffectTrackId: "+clickedEffectTrackId)
                    // console.log("clickedEffectTrackId.length: "+clickedEffectTrackId.length)
                    previousIndex = clickedEffectTrackId[clickedEffectTrackId.length - 1];
                    previousButtonElement = document.getElementById(`deleteButton-${previousIndex}`);
                    if (previousButtonElement !== null) {
                        removedStyles.forEach(className => {
                            if (!previousButtonElement.classList.contains(className)) {
                                previousButtonElement.classList.add(className);
        
                            }
                        });
                    }
                }
                
                var previousClickedEffectTrack = document.getElementById(clickedEffectTrackId);    
                if (previousClickedEffectTrack !== null) {
                    additionalStyle.forEach(className => {
                        if (previousClickedEffectTrack.classList.contains(className)) {
                            previousClickedEffectTrack.classList.remove(className);
    
                        }
                    });
                }
                additionalStyle.forEach(className => {
                    if (!element.classList.contains(className)) {
                        insertClassAtPosition(element, [className], element.classList.length)
    
                    }
                });
                removedStyles.forEach(className => {
                    if (buttonElement !== null) {
                        if (buttonElement.classList.contains(className)) {
                            buttonElement.classList.remove(className);
                        }
                    }
                   
                });
                setClickedEffectTrackId(id);
            }
        }
    }

    const deleteEffectTrack = () => {
        var index = parseInt(clickedEffectTrackId[clickedEffectTrackId.length - 1]);
        var ranges = zoomRanges;
        const beforeIndex = ranges.slice(0, index);
        var afterIndex = ranges.slice(index+1);
        var finalRanges = beforeIndex.concat(afterIndex);        
        setZoomRanges(finalRanges);
    }

    const generateEffectTracker = () => {
        var style = `flex flex-row justify-end cursor-pointer bg-purple-600 h-20 border border-purple-200 mt-2 ${marginOfPinAndVideoTrack}  rounded-lg opacity-75 absolute`
        var zoomDivs = []
        var count = 0;
        const div = document.getElementById('tracker');
        if (div === null) return;
        const divWidth = div.offsetWidth;


        zoomRanges.forEach((range) => {
            if (count !== 0) {
                var duration = playVideoRef.current.duration;
                var ratio = range.zoomInTimestamp / duration;

                const zoomInPosition = getNewPositionBasedOnRatio(ratio, divWidth);
                ratio = range.zoomOutTimestamp / duration;
                const zoomOutPosition = getNewPositionBasedOnRatio(ratio, divWidth);
                const width = `${zoomOutPosition - zoomInPosition}px`;

                const marginLeft = `${zoomInPosition}px`;
                const inlineStyle = {
                    width: `${width}`,
                    marginLeft: `${marginLeft}`
                }
                const id = `effectTrack-${count}`;
                const buttonId = `deleteButton-${count}`;

                // const inlineStyle = {width: `${width}`; margin-left: ${marginLeft}}

                var div = (
                <div id={id} key={count} onClick={() => addBorderAndDeleteButton(id)} className={style} style={inlineStyle}>
                    <button id={buttonId} type="button"  className='hidden absolute rounded-full h-5 w-5 translate-x-1 -translate-y-1 hover:bg-purple-300' onClick={() => deleteEffectTrack()}>
                        <AiFillMinusCircle color='white' className='h-full w-full'/>    
                    </button> 
                </div>
                );
                zoomDivs.push(div)
            }
            count++;
        })
        return (
            <div id="effectTrack" className='flex flex-row' on>
                {zoomDivs}

            </div>
        )
    }
    return generateEffectTracker();
}