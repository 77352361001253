import '../index.css'; 
import NavBar from "../components/NavBar"
import GeneralButton from '../components/GeneralButton';
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import { useNavigate } from 'react-router-dom';
import PricingCards from '../components/PricingCards';
import { AiOutlineArrowRight, AiOutlineZoomIn } from 'react-icons/ai';
import { Button } from '@chakra-ui/react';
import Footer from '../components/Footer';



function Section1() {
    const navigate = useNavigate();

    return (
        <div className="px-16 py-32 bg-white relative overflow-hidden justify-center text-center">
            <div className="flex flex-col justify-center bg-white text-center " >
                <h1>Unlock Precision Video Editing With FrameFocus</h1>
                <div className='flex flex-row justify-center mt-2'>
                <p className='text-2xl'>Empower Your Video Editing with Precision Zooming Tools </p><span id='magnifier' className='animate-bounce ml-2 text-3xl' >🔎</span>
                </div>

            </div>
            {/* <GeneralButton className=" font-medium button w-button h-12 w-full my-2" content='Login' navigation='./login'/> */}
            <Button type="submit" className=" font-medium button w-button h-12 w-52 mt-8" onClick={() => navigate('./login')}>
                Get started <AiOutlineArrowRight className="ml-2" />
            </Button>
        </div>
    )
}

function generateSteps(numberOfStep, stepName, description) {
    return <div className='  text-start border-2 border-dark rounded-md hover:bg-hover hover:-translate-x-2 hover:-translate-y-1 p-2 mt-2'>
    <span className='font-bold text-3xl text-darkblue'>{numberOfStep}. </span><span className='font-bold '>{stepName}: </span>{description}
</div>
}
function Section2() {

    return (
        <div className="px-16 py-32 bg-white relative overflow-hidden justify-center text-center">
            <div className="flex flex-col justify-center bg-white text-center " >
                <div className='flex flex-row justify-center'>
                <h3 className=''>Make editing video easier
                    <span className=' ml-2 absolute text-3xl rotate-12' ><span className='underline' >but how</span>? 🤔</span>
                </h3>               

                </div>
                <div className='w-full lg:px-60 mt-10'>
                    {generateSteps(1, "Zoom-in", 'Click any position of the frame of the video that you want zoom in.')}
                    {generateSteps(2, "Zoom-out", 'Click any position of the video will zoom back.')}
                    {generateSteps(3, "Render", 'Finish editing and start rendering.')}
                    {generateSteps(4, "Download", 'Looks good! Download it!!')}


                </div>
            </div>

        </div>
    )
}

function PricingSection() {
    return (
        <div id="pricing" className="px-16 py-32 bg-white relative overflow-hidden justify-center text-center">
           
            <h3>Pricing</h3>
            <h4 className='font-bold'>Unlock the power of the productive video editing</h4>

            <PricingCards/>
        </div>
    )
}
export const Home = () => (
    <>
        <NavBar/>
        <Section1/>
        <Section2/>
        <PricingSection/>
        <Footer />
    </>
)