import * as React from "react"
import { Link as ReactRouterLink } from "react-router-dom";
import {
    Box,
    Stack,
    Flex
} from "@chakra-ui/react"

import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import GeneralButton from "./GeneralButton";

function Logo() {
    return (
        <Box as={ReactRouterLink} to='/'>
            <LogoSVG className="h-14 w-60"/>
        </Box>
    )
}

const MenuItem = ({ to = "/", ...rest }) => {
    return (
        <a href={to} className="justify-center text-center self-center font-medium" {...rest}>
            <span className=" hover:text-blue-dark" {...rest}></span>
        </a>
    )
}

function NavMenu() {
    return (
        <Stack
            spacing={8}
            align="center"
            justify="center"
            direction="row"
        >
            <MenuItem to="/about">About</MenuItem>
            <MenuItem to="/#pricing">Pricing</MenuItem>
            <GeneralButton className="nav-button" content="Get Started" navigation="./login"></GeneralButton>
            =</Stack>
    )
}

export default function NavBar() {
    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            pb={2}
            pt="24px"
            pl="64px"
            pr="64px"
            bg={["white"]}
            color={["white", "white", "primary.700", "primary.700"]}
        >
            <Logo />
            <NavMenu />
        </Flex>
        // <Box
        //     as="a"
        //     href={'/'}
        //     role={'group'}
        //     display={'block'}
        //     p={2}
        //     rounded={'md'}
        //     _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
        //     <Stack direction={'row'} align={'center'}>
        //         <Box>
        //             <Text
        //                 transition={'all .3s ease'}
        //                 _groupHover={{ color: 'pink.400' }}
        //                 fontWeight={500}>
        //                 {"Hi"}
        //             </Text>
        //             <Text fontSize={'sm'}>{"Hi"}</Text>
        //         </Box>
        //         <Flex
        //             transition={'all .3s ease'}
        //             transform={'translateX(-10px)'}
        //             opacity={0}
        //             _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
        //             justify={'flex-end'}
        //             align={'center'}
        //             flex={1}>
        //             <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        //         </Flex>
        //     </Stack>
        // </Box>
    )
}