import { Link, Link as ReactRouterLink } from "react-router-dom";
import {
    Box
} from "@chakra-ui/react"
import { ReactComponent as LogoSVG } from '../assets/logo.svg';
import { useNavigate } from 'react-router-dom';


function Logo() {
    return (
        <Box as={ReactRouterLink} to='/'>
            <LogoSVG className=" h-8 w-36"/>
        </Box>
    )
}

export default function Footer() {
    return (
        <>
            <footer className=" border-t-2  bg-gray-100  p-40  bottom-0 ">
                <div className="grid grid-rows-1 grid-flow-col gap-1">
                    <div className="flex flex-col justify-center space-y-3">
                        <Logo className="" />
                        <span className="text-sm" >Empower Your Video Editing with Precision Zooming Tools</span>
                        <span className=" text-gray-500">Copyright © 2024 - All rights reserved</span>
                    </div>

                    <div className="flex flex-col space-y-3">
                        <span className="font-semibold text-gray-500">Links</span>
                        <Link to={'/login'} className=" no-underline font-normal hover:underline hover:text-gray-500" >Login</Link>
                        <Link to={'/#pricing'} className=" no-underline	font-normal hover:underline hover:text-gray-500" >Pricing</Link>

                    </div>

                    <div className="flex flex-col space-y-3">
                        <span className="font-semibold text-gray-500">Legal</span>
                        <Link to={'/term-of-service'} className=" no-underline font-normal hover:underline hover:text-gray-500" >Term of Service</Link>
                        <Link to={'/privacy-policy'} className=" no-underline	font-normal hover:underline hover:text-gray-500" >Privacy Policy</Link>

                    </div>

                
                </div>
            </footer>

        </>
    )
}